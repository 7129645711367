import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Form } from 'semantic-ui-react'
import { Numpad } from '../components'
import { alertGenerator, baseViewDispatch } from '../lib/util'

const bg_image = require('assets/login.png')
const bg_pin_image = require('assets/login.png')

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            password: '',
            pin: '',
        }
    }

    componentDidUpdate() {
        if (this.state.pin.length === 4) {
            this.doWorkerLogin()
        }
        // let { login, loginEmployee, isEmployee } = this.props

        // let { fetching, status } = isEmployee ? loginEmployee : login

        // if (!fetching && status.success) {
        // }
        // if (fetching) {
        //     console.log('fetching')
        // }
        // if (!fetching && status.error) {
        // console.log('error')
        // }
    }

    doWorkerLogin() {
        let { pin } = this.state
        let { network } = this.props

        network.loginEmployee(pin)
        this.setState({
            pin: '',
        })
    }

    render() {
        let { login, loginEmployee, network, isEmployee, success, error } = this.props
        let { email, password } = this.state

        let { fetching } = isEmployee ? loginEmployee : login
        return (
            <View fullh fullw>
                <View style={isEmployee ? styles.bgEmployee : styles.bg} />
                <Form style={{ height: '100%', width: '100%' }}>
                    <View column fullh fullw>
                        {isEmployee ? (
                            <View fullw fullh column around>
                                <View
                                    style={{
                                        width: '60%',
                                        margin: '0px auto',
                                        justifyContent: 'center',
                                        maxWidth: '90vw',
                                    }}
                                >
                                    <Form.Input
                                        style={{ fontSize: '-webkit-xxx-large', maxWidth: '90vw' }}
                                        label={<span className="label label-pin-field">Pin</span>}
                                        type="password"
                                        value={this.state.pin}
                                        onChange={(e, { value }) => this.setState({ pin: value })}
                                    />
                                </View>
                                <View fullw style={{ height: '20%' }}>
                                    <Numpad
                                        isString
                                        numpadOnly
                                        label={<span className="label">Inserisci pin</span>}
                                        value={this.state.pin}
                                        onChange={(value) => {
                                            this.setState({ pin: value })
                                        }}
                                    />
                                </View>
                            </View>
                        ) : (
                            <div style={{ position: 'absolute', top: '20%', right: '10%' }}>
                                <Form.Input
                                    label={<span className="label label-email-field">Email</span>}
                                    name="email"
                                    data-testid="email"
                                    placeholder="Inserire il nome utente"
                                    onChange={(e, { value }) => this.setState({ email: value })}
                                />
                                <Form.Input
                                    label={<span className="label label-password-field">Password</span>}
                                    type="password"
                                    data-testid="password"
                                    onChange={(e, { value }) => this.setState({ password: value })}
                                />
                                <Button
                                    className="button-login"
                                    loading={fetching}
                                    color="black"
                                    floated="right"
                                    onClick={() =>
                                        network
                                            .login(email, password)
                                            .then((result) => {
                                                success(alertGenerator({ title: 'Accesso effettuato!', message: '' }))
                                            })
                                            .catch((err) => {
                                                error(alertGenerator({ title: 'Errore di accesso', message: 'Controlla email e password' }))
                                            })
                                    }
                                >
                                    Accedi
                                </Button>
                                {process.env.REACT_APP_NETWORK && (
                                    <Button
                                        color="orange"
                                        floated="right"
                                        onClick={() => window.open(process.env.PUBLIC_URL + '/RichiestaDigitalArtisan.pdf')}
                                    >
                                        Richiesta Registrazione
                                    </Button>
                                )}
                            </div>
                        )}
                    </View>
                </Form>
            </View>
        )
    }
}

const mapStateToProps = (state) => {
    let { login, loginEmployee } = state

    return {
        login,
        loginEmployee,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Login)))

const styles = {
    form: {
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: -200,
    },
    bg: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: `url(${bg_image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        filter: 'blur(5px)',
        WebkitFilter: 'blur(5px)',
    },
    bgEmployee: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: `url(${bg_pin_image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        filter: 'blur(5px)',
        WebkitFilter: 'blur(5px)',
    },
}
