import { connectNetwork } from 'lib/NetworkProvider'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Image } from 'semantic-ui-react'
import defaultImage from '../assets/default.png'

class ImageDefault extends Component {
    render() {
        let { fromRoot, src, network, dispatch, staticContext, ...rest } = this.props

        let image = src && src !== '' ? src : defaultImage

        if (fromRoot && !image.startsWith('http')) {
            image = `${network.getUrl()}${image}`
        }

        const handleError = ({ currentTarget }) => {
            currentTarget.onerror = null // prevents looping
            const originalSrc = currentTarget.src

            if (originalSrc.endsWith('.png.bmp')) {
                currentTarget.src = defaultImage
            } else if (originalSrc.endsWith('.jpg.bmp')) {
                currentTarget.src = originalSrc.replace('.jpg.bmp', '.png.bmp')
            } else if (originalSrc.endsWith('.bmp')) {
                currentTarget.src = originalSrc.replace('.bmp', '.jpg.bmp')
            } else {
                currentTarget.src = originalSrc.replace(/(\.\w+)?$/, '.bmp')
            }

            currentTarget.onerror = ({ currentTarget }) => {
                currentTarget.onerror = null // prevents looping
                if (currentTarget.src.endsWith('.jpg.bmp')) {
                    currentTarget.src = currentTarget.src.replace('.jpg.bmp', '.png.bmp')
                } else if (currentTarget.src.endsWith('.bmp')) {
                    currentTarget.src = currentTarget.src.replace('.bmp', '.jpg.bmp')
                } else {
                    currentTarget.src = defaultImage
                }
            }
        }

        return src && src !== '' ? (
            <Image
                onError={handleError}
                src={image}
                {...rest}
            />
        ) : (
            <Image {...rest} src={defaultImage} />
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {}
}

export default withRouter(connect(mapStateToProps)(connectNetwork(ImageDefault)))
