import {} from 'containers'
import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'

import { connect } from 'react-redux'
import { Button, Input, Label } from 'semantic-ui-react'

class Numpad extends Component {
    constructor(props) {
        super(props)
        this.state = {
            stringValue: [],
            value: 0,
            digits: ['7', '8', '9', '4', '5', '6', '1', '2', '3', '0', 'C', 'CE'],
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let { onChange, isString } = this.props
        let { value, stringValue } = this.state
        let { value: prevValue, stringValue: prevStringValue } = prevState

        if (!onChange) return
        if (value !== prevValue || stringValue !== prevStringValue) {
            if (isString) {
                return onChange(stringValue.join(''))
            }
            return onChange(parseInt(value))
        }
    }

    pressDigit = (digit) => {
        if (digit === 'C') {
            this.removeDigit()
        } else if (digit === 'CE') {
            this.setState({
                value: 0,
                stringValue: [],
            })
        } else {
            let num = parseInt(digit)
            this.addDigit(num)
        }
    }

    removeDigit = () => {
        let { isString, value } = this.props
        let { stringValue, value: _value } = this.state

        if (isString) {
            let newValue = value !== undefined ? value.split('') : stringValue
            newValue.pop()
            return this.setState({ stringValue: [...newValue] })
        }

        let newValue = value ? parseInt(value) : _value
        newValue = Math.floor(newValue / 10)

        this.setState({
            value: newValue,
        })
    }

    addDigit = (num) => {
        let { isString, value } = this.props
        let { stringValue, value: _value } = this.state

        if (isString) {
            let newValue = value !== undefined ? value.split('') : stringValue
            newValue.push(num)
            return this.setState({ stringValue: [...newValue] })
        }

        let newValue = value ? parseInt(value) : _value
        newValue = newValue * 10 + num
        this.setState({
            value: newValue,
        })
    }

    renderDigits = (digits) => {
        const components = digits.map((value, index) => {
            return (
                <Button
                    key={index}
                    style={{
                        flex: 1,
                    }}
                    color={value === 'C' || value === 'CE' ? 'red' : 'grey'}
                    onClick={() => {
                        this.pressDigit(value)
                    }}
                >
                    {value}
                </Button>
            )
        })

        return (
            <div>
                <div>
                    {components[1]}
                    {components[2]}
                    {components[3]}
                </div>
                <div>
                    {components[4]}
                    {components[5]}
                    {components[6]}
                </div>
                <div>
                    {components[7]}
                    {components[8]}
                    {components[9]}
                </div>
                <div>
                    {components[0]}
                    {components[0]}
                    {components[10]}
                </div>
            </div>
        )
    }

    renderDigitRow(number) {
        let { digits } = this.state
        let digrow = digits.slice(number * 3, number * 3 + 3)

        return (
            <View fullw row style={{ flexGrow: 0.15 }}>
                {this.renderDigits(digrow)}
            </View>
        )
    }

    render() {
        let { onChange, value, label, numpadOnly, placeholder } = this.props

        return (
            <View column around style={styles.container}>
                <View around column>
                    <Label style={{ borderRadius: 0 }}>{label}</Label>
                    {numpadOnly !== true && (
                        <Input
                            value={value}
                            type="number"
                            placeholder={placeholder}
                            onChange={(e, data) => {
                                if (data.value < 0) data.value = 0
                                if (onChange) {
                                    onChange(data.value)
                                }
                                this.setState({ value: data.value })
                            }}
                        />
                    )}
                </View>
                {numpadOnly !== true && (
                    <View column fullw fullh around style={{ height: 300 }}>
                        {this.renderDigitRow(0)}
                        {this.renderDigitRow(1)}
                        {this.renderDigitRow(2)}
                        {this.renderDigitRow(3)}
                    </View>
                )}
                {numpadOnly && (
                    <View row fullw fullh around>
                        {this.renderDigits([...Array.from({ length: 10 }, (v, i) => i), 'C'])}
                    </View>
                )}
            </View>
        )
    }
}

const styles = {
    container: {
        width: '90%',
        margin: '0px auto',
    },
}

const mapStateToProps = (state, ownProps) => {
    return {}
}
export default connect(mapStateToProps)(connectNetwork(Numpad))
