import { SideBar, ToolBar } from 'components'
import {
    ArticleEditor,
    Articles,
    AssignVariants,
    CalendarView,
    Categories,
    CategoryEditor,
    Chat,
    CollectionEditor,
    Collections,
    ContractEditor,
    Contracts,
    CorpEditor,
    Corps,
    CostEditor,
    Dashboard,
    DataImport,
    DepositEditor,
    Deposits,
    DetailView,
    DocumentEditor,
    Documents,
    Duplicates,
    Families,
    FamilyEditor,
    FinalBalance,
    JobEditor,
    Jobs,
    LabelEditor,
    MachineEditor,
    Machines,
    OfficeEditor,
    Offices,
    OrderingGrids,
    Organizations,
    ProductEditor,
    Products,
    Settings,
    SingleContract,
    SingleDocument,
    SingleJob,
    SingleMachine,
    SingleOrganization,
    SingleProduct,
    SingleStock,
    SingleWorker,
    Stock,
    StockEditor,
    TerminalView,
    TurnEditor,
    Turns,
    UserEditor,
    Users,
    VariantEditor,
    Variants,
    Warehouse,
    WorkAssignment,
    WorkerEditor,
    WorkerPanel,
    Workers,
} from 'containers'
import Listings from 'job/Listings'
import CustomLabels from 'labeling/CustomLabels'
import Labeling from 'labeling/Labeling'
import Packaging from 'labeling/Packaging'
import ProductionLabels from 'labeling/ProductionLabels'
import WarehouseLabels from 'labeling/WarehouseLabels'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import React, { Component } from 'react'
import { withCookies } from 'react-cookie'
import { isMobile } from 'react-device-detect'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import Notification from 'react-web-notification'
import { SEARCH_ACTIONS } from '../actions/search'
import { SENDNOTIFICATION_ACTIONS } from '../actions/sendNotification'
import AdminCalendar from '../calendar/AdminCalendar'
import CalendarEditor from '../calendar/CalendarEditor'
import ScheduleEditor from '../calendar/ScheduleEditor'
import { BabyPressa, Badges, BadgeScans, GT2000, JobMerge, Monitor, SWA300, SWA300Editor, Tasks } from '../containers'
import PdfExport from '../export/PdfExport'
import FileImport from '../import/FileImport'
import ClientOrderEditor from '../job/ClientOrderEditor'
import ClientOrders from '../job/ClientOrders'
import Customers from '../job/Customers'
import JobWizard from '../job/JobWizard'
import MoldMappingTable from '../job/MoldMappingTable'
import SingleClientOrder from '../job/SingleClientOrder'
import { Default, Mobile } from '../lib/util'
import Kits from '../printing/Kits'
import Masters from '../printing/Masters'
import MaterialCheck from '../printing/MaterialCheck'
import Materials from '../printing/Materials'
import Recipes from '../printing/Recipes'
import Molds from '../printing/Molds'
import Packages from '../printing/Packages'
import Productions from '../printing/Productions'
import WorkOrders from '../printing/WorkOrders'
import TaskDashboard from '../tasks/TaskDashboard'
import TaskList from '../tasks/TaskList'
import AnalyticRealTime from '../widgets/AnalyticRealTime'
import Ind40Visualizer from '../widgets/Ind40Visualizer'
import OeeVisualizer from '../widgets/OeeVisualizer'
import WidgetContainer from '../widgets/WidgetContainer'
import FilterBar from './FilterBar'
import MenuBar from './MenuBar'
import SortBar from './SortBar'
import { Box } from '@chakra-ui/react'
import FanucState from 'widgets/FanucState'
import Preferences from 'user/Preferences'
import WarehouseLoad from 'stock/WarehouseLoad'
import WarehouseUnload from 'stock/WarehouseUnload'
import Shipments from 'stock/Shipments'
import WarehouseConfirm from 'stock/WarehouseConfirm'

const {
    dashboard,
    app,
    jobs,
    jobEdit,
    turnEdit,
    userEdit,
    workerEdit,
    machineEdit,
    categoryEdit,
    depositEdit,
    contractEdit,
    works,
    employees,
    machines,
    deposits,
    products,
    categories,
    corps,
    contracts,
    organizations,
    productEdit,
    corpEdit,
    admin,
    employee,
    insertion,
    terminal,
    finalBalance,
    costEdit,
    costUpdate,
    calendarView,
    tempJobs,
    newEl,
    settings,
    turns,
    stock,
    stockEdit,
    documents,
    documentEdit,
    message,
    profile,
    familyEdit,
    articleEdit,
    variantEdit,
    offices,
    assignvariants,
    families,
    articles,
    variants,
    ordering,
    collections,
    duplicates,
    collectionEdit,
    officeEdit,
    warehouse,
    dataimport,
    pdfexport,
    moldmapping,
    jobMerge,
    babypressa,
    gt2000,
    saldatriceeditor,
    saldatrice,
    monitor,
    tasks,
    clientOrders,
    clientOrderEdit,
    oeenegribossia,
    oeenegribossib,
    oeenegribossic,
    oeenegribossid,
    negribossia,
    negribossib,
    negribossic,
    negribossid,
    wittman,
    realtimedata,
    taskdashboard,
    taskdetail,
    scheduleEditor,
    calendarEditor,
    badgescan,
    badge,
    masters,
    materials,
    packages,
    kits,
    machine1,
    oeemachine1,
    molds,
    materialcheck,
    customers,
    printjobs,
    printjobsdetail,
    jobwizard,
    jobwizardproduction,
    ind40visualizer,
    fileimport,
    workorderprint,
    productions,
    articleClone,
    printjobshistory,
    labeling,
    packaging,
    warehouselabels,
    productionlabels,
    customlabels,
    labeleditor,
    listings,
    recipes,
    productionCalendar,
    fanucstate,
    preferences,
    warehouseload,
    warehouseunload,
    warehouseconfirm,
    shipments,
} = routes

class Main extends Component {
    constructor(props) {
        super(props)
        this.state = {
            generalItem: false,
            fetching: false,
            ignore: true,
            title: '',
        }
    }

    handlePermissionGranted() {
        // console.log('Permission Granted')
        this.setState({
            ignore: false,
        })
    }
    handlePermissionDenied() {
        // console.log('Permission Denied')
        this.setState({
            ignore: true,
        })
    }
    handleNotSupported() {
        // console.log('Web Notification not Supported')
        this.setState({
            ignore: true,
        })
    }

    handleNotificationOnClick(e, tag) {
        // console.log(e, 'Notification clicked tag:' + tag)
    }

    handleNotificationOnError(e, tag) {
        // console.log(e, 'Notification error tag:' + tag)
    }

    handleNotificationOnClose(e, tag) {
        // console.log(e, 'Notification closed tag:' + tag)
    }

    handleNotificationOnShow(e, tag) {
        this.playSound()
        // console.log(e, 'Notification shown tag:' + tag)
    }

    playSound() {
        // document.getElementById('sound').play()
    }

    handleButtonClick() {
        if (this.state.ignore) {
            return
        }

        const now = Date.now()

        const title = 'React-Web-Notification' + now
        const body = 'Hello' + new Date()
        const tag = now
        // const icon = 'http://mobilusoss.github.io/react-web-notification/example/Notifications_button_24.png'
        // const icon = 'http://localhost:3000/Notifications_button_24.png';

        // Available options
        // See https://developer.mozilla.org/en-US/docs/Web/API/Notification/Notification
        const options = {
            tag: tag,
            body: body,
            // icon: icon,
            lang: 'en',
            dir: 'ltr',
            //sound: './sound.mp3' // no browsers supported https://developer.mozilla.org/en/docs/Web/API/notification/sound#Browser_compatibility
        }
        this.setState({
            title: title,
            options: options,
        })
    }

    handleButtonClick2() {
        this.props.swRegistration.getNotifications({}).then(function (notifications) {
            // console.log(notifications)
        })
    }

    componentDidMount() {
        let { history } = this.props

        // sendNotificationResult({
        // 	title: 'Testing notifica',
        // 	tag: 'Tag (now?)',
        // 	body: 'Body della notifica, testo arbitrario',
        // 	// icon: icon,
        // 	lang: 'en', // ?
        // 	dir: 'ltr' // Left to right ?
        // })

        this.unlisten = history.listen((location, action) => {
            let { pathname } = location
            let position = this.state[pathname] || 0

            let main = document.getElementsByClassName('main')[0]
            if (main && position !== 0) {
                //console.log(position, main);
                main.scrollTo({ top: position, left: 0, behavior: 'smooth' })
            }

            // console.log('History listen ', location, action)
            this.props.searchResult('')
        })
    }

    componentWillUnmount() {
        this.unlisten()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { fetching, sendNotification } = this.props
        //console.log("Main did update checking fetching:", fetching, prevProps.fetching);
        if (fetching !== prevProps.fetching) {
            this.setState({ fetching })
        }

        if (sendNotification.data !== prevProps.sendNotification.data) {
            const options = sendNotification.data

            this.setState({
                title: sendNotification.data.title,
                options: options,
            })
        }
    }

    render() {
        let { isEmployee } = this.props
        let {
            location: { pathname },
        } = this.props.history

        const areWeMobile = isMobile
        /* eslint-disable */
        console.log('isMobile', isMobile)

        const content = (
            <View
                className="main"
                fullh
                column
                fullw
                style={{
                    overflowY: 'auto',
                    textAlign: 'center',
                    height: 'calc(100% - 50px)',
                    ...(!areWeMobile && { borderLeft: '1px solid rgba(34, 36, 38, .15)' }),
                }}
                monitorPos={(pos) => this.setState({ [pathname]: pos })}
            >
                <MenuBar
                    toolbar={this.props.toolbar.data}
                    style={{
                        borderRadius: '0px',
                        marginBottom: 0,
                        // ...(!areWeMobile && { width: 'calc(100vw - 110px)' }),
                        overflow: 'auto',
                    }}
                />
                <View
                    className="main"
                    fullh
                    fullw
                    column
                    style={{
                        overflowY: 'auto',
                        textAlign: 'center',
                    }}
                    monitorPos={(pos) => this.setState({ [pathname]: pos })}
                >
                    {!isEmployee && <FilterBar />}
                    {!isEmployee && <SortBar />}
                    <Box
                        h="100%"
                        w="100%"
                        overflow="scroll"
                        style={{
                            maxWidth: isEmployee ? '100%' : '1800px',
                            textAlign: 'left',
                            margin: '0px auto',
                            ...(!areWeMobile && { width: 'calc(100vw - 110px)' }),
                        }}
                    >
                        {isEmployee && (
                            <Switch>
                                <Route exact path={route_from(employee)} render={() => <WorkerPanel />} />

                                <Route exact path={route_from(employee, terminal)} render={() => <TerminalView />} />

                                <Route exact path={route_from(employee, insertion)} render={() => <DetailView />} />

                                <Route exact path={route_from(employee, packaging)} render={() => <Packaging />} />

                                <Route
                                    exact
                                    path={route_from(employee, insertion, calendarView)}
                                    render={() => <CalendarView showWorkHours isEmployee={true} />}
                                />
                                <Route
                                    exact
                                    path={route_from(employee, calendarView)}
                                    render={() => <CalendarView showWorkHours isEmployee={true} />}
                                />
                                <Route exact path={route_from(employee, moldmapping)} render={() => <MoldMappingTable isEmployee={true} />} />

                                <Route exact path={route_from(employee, materialcheck)} render={() => <MaterialCheck isEmployee={true} />} />
                            </Switch>
                        )}
                        {!isEmployee && (
                            <Switch>
                                <Route exact path={route_from(app, labeling)} render={() => <Labeling />} />

                                <Route exact path={route_from(app, packaging)} render={() => <Packaging />} />

                                <Route exact path={route_from(app, warehouselabels)} render={() => <WarehouseLabels />} />
                                <Route exact path={route_from(app, productionlabels)} render={() => <ProductionLabels />} />
                                <Route exact path={route_from(app, customlabels)} render={() => <CustomLabels />} />
                                <Route exact path={route_from(app, labeleditor)} render={() => <LabelEditor />} />

                                <Route exact path={route_from(app, realtimedata)} render={() => <WidgetContainer />} />

                                <Route exact path={route_from(app, ind40visualizer)} render={() => <Ind40Visualizer />} />
                                <Route exact path={route_from(app, fanucstate)} render={() => <FanucState />} />

                                <Route exact path={route_from(app, preferences)} render={() => <Preferences />} />

                                <Route exact path={route_from(app, productions)} render={() => <Productions />} />

                                <Route exact path={route_from(app, oeemachine1)} render={() => <OeeVisualizer table="machine1" />} />

                                {/* <Route exact path={route_from(app, negribossia)} render={() => <Ind40Visualizer table="negribossi1" />} /> */}

                                {/* <Route
                                    exact
                                    path={route_from(app, negribossia)}
                                    render={() => <AnalyticRealTime table="negribossi1" />}
                                /> */}

                                {/* <Route exact path={route_from(app, negribossib)} render={() => <Ind40Visualizer table="negribossi2" />} /> */}

                                {/* <Route
                                    exact
                                    path={route_from(app, negribossib)}
                                    render={() => <AnalyticRealTime table="negribossi2" />}
                                /> */}

                                <Route exact path={route_from(app, oeenegribossia)} render={() => <OeeVisualizer table="negribossi1" />} />
                                <Route exact path={route_from(app, oeenegribossib)} render={() => <OeeVisualizer table="negribossi2" />} />

                                <Route exact path={route_from(app, oeenegribossic)} render={() => <OeeVisualizer table="negribossi3" />} />
                                <Route exact path={route_from(app, oeenegribossid)} render={() => <OeeVisualizer table="negribossi4" />} />

                                <Route exact path={route_from(app, fileimport)} render={() => <FileImport table="wittman" />} />

                                <Route exact path={route_from(app, wittman)} render={() => <AnalyticRealTime table="wittman" />} />

                                <Route exact path={route_from(app, molds)} render={() => <Molds isEmployee={false} />} />
                                <Route exact path={route_from(app, masters)} render={() => <Masters isEmployee={false} />} />
                                <Route exact path={route_from(app, printjobsdetail)} render={() => <WorkOrders isEmployee={false} />} />
                                <Route
                                    exact
                                    path={route_from(app, printjobshistory)}
                                    render={() => <WorkOrders isEmployee={false} showHistory={true} />}
                                />
                                <Route exact path={route_from(app, materials)} render={() => <Materials isEmployee={false} />} />
                                <Route exact path={route_from(app, recipes)} render={() => <Recipes isEmployee={false} />} />
                                <Route exact path={route_from(app, packages)} render={() => <Packages isEmployee={false} />} />
                                <Route exact path={route_from(app, kits)} render={() => <Kits isEmployee={false} />} />

                                <Route exact path={route_from(app, listings)} render={() => <Listings isEmployee={false} />} />

                                <Route exact path={route_from(app, tasks)} render={() => <Tasks isEmployee={false} />} />
                                <Route exact path={route_from(app, taskdashboard)} render={() => <TaskDashboard isEmployee={false} />} />
                                <Route exact path={route_from(app, taskdetail)} render={() => <TaskList isEmployee={false} />} />

                                <Route exact path={route_from(app, babypressa)} render={() => <BabyPressa isEmployee={false} />} />
                                <Route exact path={route_from(app, gt2000)} render={() => <GT2000 isEmployee={false} />} />
                                <Route exact path={route_from(app, monitor)} render={() => <Monitor isEmployee={false} />} />
                                <Route exact path={route_from(app, saldatrice)} render={() => <SWA300 isEmployee={false} />} />
                                <Route exact path={route_from(app, saldatriceeditor)} render={() => <SWA300Editor isEmployee={false} />} />

                                {/* <Route exact path={route_from(app, moldmapping)} render={() => <MoldMapping isEmployee={false} />} /> */}
                                <Route exact path={route_from(app, moldmapping)} render={() => <MoldMappingTable isEmployee={false} />} />

                                <Route path={route_from(app, tempJobs, jobMerge, ':job')} component={JobMerge} />

                                <Route exact path={route_from(app, calendarView)} render={() => <AdminCalendar showWorkHours />} />
                                <Route exact path={route_from(app, productionCalendar)} render={() => <AdminCalendar showWorkOrders />} />

                                <Route exact path={route_from(app, scheduleEditor)} render={() => <ScheduleEditor />} />
                                <Route exact path={route_from(app, calendarEditor)} render={() => <CalendarEditor />} />
                                <Route exact path={route_from(app, badgescan)} render={() => <BadgeScans />} />
                                <Route exact path={route_from(app, badge)} render={() => <Badges />} />

                                <Route exact path={route_from(app, jobs)} render={() => <Jobs jobType={2} direction="column" type="full" />} />
                                <Route
                                    exact
                                    path={route_from(app, printjobs)}
                                    render={() => <Jobs jobType={3} direction="column" type="full" hideOther />}
                                />

                                <Route exact path={route_from(app, jobwizard)} component={JobWizard} />
                                <Route exact path={route_from(app, jobwizardproduction)} component={JobWizard} />

                                <Route path={route_from(app, jobs, jobEdit, ':job')} component={JobEditor} />
                                <Route exact path={route_from(app, jobs, newEl, jobEdit)} component={JobEditor} />
                                <Route exact path={route_from(app, jobs, ':job')} component={SingleJob} />

                                <Route exact path={route_from(app, clientOrders)} render={() => <ClientOrders direction="column" type="full" />} />
                                <Route path={route_from(app, clientOrders, clientOrderEdit, ':clientOrder+')} component={ClientOrderEditor} />
                                <Route exact path={route_from(app, clientOrders, newEl, clientOrderEdit)} component={ClientOrderEditor} />
                                <Route exact path={route_from(app, clientOrders, ':clientOrder+')} component={SingleClientOrder} />

                                <Route exact path={route_from(app, employees)} component={Workers} />
                                <Route path={route_from(app, employees, workerEdit, ':worker')} component={WorkerEditor} />
                                <Route exact path={route_from(app, employees, newEl, workerEdit)} component={WorkerEditor} />
                                <Route exact path={route_from(app, employees, ':worker')} component={SingleWorker} />
                                <Route exact path={route_from(app, works)} render={() => <WorkAssignment />} />

                                <Route exact path={route_from(app, machines)} component={Machines} />
                                <Route path={route_from(app, machines, machineEdit, ':machine')} component={MachineEditor} />
                                <Route exact path={route_from(app, machines, newEl, machineEdit)} component={MachineEditor} />
                                <Route exact path={route_from(app, machines, ':machine')} component={SingleMachine} />

                                <Route exact path={route_from(app, warehouse)} component={Warehouse} />
                                <Route exact path={route_from(app, warehouseload)} render={() => <WarehouseLoad />} />
                                <Route exact path={route_from(app, warehouseunload)} render={() => <WarehouseUnload />} />
                                <Route exact path={route_from(app, warehouseconfirm)} render={() => <WarehouseConfirm />} />

                                <Route exact path={route_from(app, shipments)} render={() => <Shipments />} />

                                <Route exact path={route_from(app, stock)} component={Stock} />
                                <Route path={route_from(app, stock, stockEdit, ':deposit', ':stock*')} component={StockEditor} />
                                <Route exact path={route_from(app, stock, newEl, stockEdit)} component={StockEditor} />
                                <Route exact path={route_from(app, stock, ':stock')} component={SingleStock} />

                                <Route exact path={route_from(app, organizations)} component={Organizations} />
                                <Route
                                    exact
                                    path={route_from(app, profile, ':organization')}
                                    render={(props) => <SingleOrganization key={route_from(app, profile, ':organization')} {...props} />}
                                />
                                <Route exact path={route_from(app, organizations, ':organization')} component={SingleOrganization} />

                                <Route
                                    exact
                                    path={route_from(app, tempJobs)}
                                    render={() => <Jobs createdExternal direction="column" type="full" />}
                                />
                                <Route path={route_from(app, jobs, finalBalance, ':job')} component={FinalBalance} />
                                <Route exact path={route_from(app, jobs, ':job', finalBalance)} component={FinalBalance} />

                                <Route path={route_from(app, jobs, costEdit, ':job')} component={CostEditor} />
                                <Route path={route_from(app, jobs, costUpdate, ':cost')} component={CostEditor} />

                                <Route path={route_from(app, turns, turnEdit, ':turn')} component={TurnEditor} />
                                <Route exact path={route_from(app, turns, turnEdit)} component={TurnEditor} />

                                <Route path={route_from(app, admin, userEdit, ':user')} component={UserEditor} />
                                <Route exact path={route_from(app, admin, userEdit)} component={UserEditor} />

                                <Route exact path={route_from(app, documents)} component={Documents} />
                                <Route path={route_from(app, documents, documentEdit, ':document')} component={DocumentEditor} />
                                <Route exact path={route_from(app, documents, newEl, documentEdit)} component={DocumentEditor} />
                                <Route exact path={route_from(app, documents, ':document')} component={SingleDocument} />

                                <Route exact path={route_from(app, products)} component={Products} />
                                <Route path={route_from(app, products, productEdit, ':product*')} component={ProductEditor} />
                                <Route exact path={route_from(app, products, newEl, productEdit)} component={ProductEditor} />
                                <Route exact path={route_from(app, products, ':product')} component={SingleProduct} />

                                <Route exact path={route_from(app, categories)} render={() => <Categories selectable={false} />} />
                                <Route path={route_from(app, products, categoryEdit, ':category*')} component={CategoryEditor} />
                                <Route exact path={route_from(app, products, newEl, categoryEdit)} component={CategoryEditor} />
                                <Route path={route_from(app, categories, categoryEdit, ':category*')} component={CategoryEditor} />
                                <Route exact path={route_from(app, categories, newEl, categoryEdit)} component={CategoryEditor} />

                                <Route exact path={route_from(app, corps)} render={() => <Corps selectable={false} />} />
                                <Route path={route_from(app, products, corpEdit, ':corp*')} component={CorpEditor} />
                                <Route exact path={route_from(app, products, newEl, corpEdit)} component={CorpEditor} />
                                <Route path={route_from(app, corps, corpEdit, ':corp*')} component={CorpEditor} />
                                <Route exact path={route_from(app, corps, newEl, corpEdit)} component={CorpEditor} />

                                <Route exact path={route_from(app, deposits)} render={() => <Deposits selectable={false} />} />
                                <Route path={route_from(app, deposits, depositEdit, ':deposit')} component={DepositEditor} />
                                <Route exact path={route_from(app, deposits, newEl, depositEdit)} component={DepositEditor} />

                                <Route exact path={route_from(app, variants)} component={Variants} />
                                <Route path={route_from(app, variants, variantEdit, ':variant*')} component={VariantEditor} />
                                <Route exact path={route_from(app, variants, newEl, variantEdit)} component={VariantEditor} />
                                <Route exact path={route_from(app, assignvariants)} component={AssignVariants} />

                                <Route exact path={route_from(app, customers)} component={Customers} />
                                <Route exact path={route_from(app, articles)} component={Articles} />
                                <Route path={route_from(app, articles, articleEdit, ':article*')} component={ArticleEditor} />
                                <Route path={route_from(app, articles, articleClone, ':article*')} render={() => <ArticleEditor isCloning />} />
                                <Route exact path={route_from(app, articles, newEl, articleEdit)} component={ArticleEditor} />
                                <Route exact path={route_from(app, duplicates)} component={Duplicates} />

                                <Route exact path={route_from(app, families)} component={Families} />
                                <Route path={route_from(app, families, familyEdit, ':family*')} component={FamilyEditor} />
                                <Route exact path={route_from(app, families, newEl, familyEdit)} component={FamilyEditor} />

                                <Route exact path={route_from(app, collections)} component={Collections} />
                                <Route path={route_from(app, collections, collectionEdit, ':collection*')} component={CollectionEditor} />
                                <Route exact path={route_from(app, collections, newEl, collectionEdit)} component={CollectionEditor} />

                                <Route exact path={route_from(app, offices)} component={Offices} />
                                <Route path={route_from(app, offices, officeEdit, ':office*')} component={OfficeEditor} />
                                <Route exact path={route_from(app, offices, newEl, officeEdit)} component={OfficeEditor} />

                                <Route exact path={route_from(app, contracts)} component={Contracts} />
                                <Route path={route_from(app, contracts, contractEdit, ':contract')} component={ContractEditor} />
                                <Route exact path={route_from(app, contracts, newEl, contractEdit)} component={ContractEditor} />
                                <Route exact path={route_from(app, contracts, ':contract')} component={SingleContract} />

                                <Route path={route_from(app, pdfexport)} component={PdfExport} />

                                <Route path={route_from(app, dataimport)} component={DataImport} />

                                <Route path={route_from(app, dashboard)} component={Dashboard} />

                                <Route path={route_from(app, message)} component={Chat} />

                                <Route exact path={route_from(app, ordering)} component={OrderingGrids} />

                                <Route exact path={route_from(app, settings)} component={Settings} />

                                <Route exact path={route_from(app, admin)} component={Users} />

                                <Route exact path={route_from(app, turns)} component={Turns} />
                            </Switch>
                        )}
                    </Box>
                </View>
            </View>
        )

        return (
            <View fullh fillw column>
                <View fullh row>
                    <Default>
                        <SideBar isEmployee={isEmployee} />
                    </Default>
                    <Notification
                        ignore={this.state.ignore && this.state.title !== ''}
                        notSupported={this.handleNotSupported.bind(this)}
                        onPermissionGranted={this.handlePermissionGranted.bind(this)}
                        onPermissionDenied={this.handlePermissionDenied.bind(this)}
                        onShow={this.handleNotificationOnShow.bind(this)}
                        onClick={this.handleNotificationOnClick.bind(this)}
                        onClose={this.handleNotificationOnClose.bind(this)}
                        onError={this.handleNotificationOnError.bind(this)}
                        timeout={5000}
                        title={this.state.title}
                        options={this.state.options}
                        swRegistration={this.props.swRegistration}
                    />
                    {/* <Notifications notifications={notifications} /> */}

                    <Default>
                        <Box h="100%" w="100%">
                            <ToolBar isEmployee={isEmployee} />
                            {content}
                        </Box>
                    </Default>
                    <Mobile>
                        <Box h="95%" w="100%">
                            <ToolBar isEmployee={isEmployee} />
                            {content}
                        </Box>
                    </Mobile>
                </View>
                <Mobile>
                    <SideBar isEmployee={isEmployee} />
                </Mobile>
            </View>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),

        sendNotificationResult: (result) => SENDNOTIFICATION_ACTIONS.result(result, dispatch),
    }
}

const mapStateToProps = (state) => {
    let { login, loginEmployee, user, toolbar, sendNotification } = state

    let fetching = false

    for (let key in state) {
        let redobj = state[key]
        if (redobj && redobj.fetching) {
            fetching = true
            break
        }
    }

    return {
        sendNotification,
        login,
        loginEmployee,
        user,
        toolbar,
        fetching,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(withCookies(Main))))
